










































// @ts-ignore
import VueSlickCarousel from 'vue-slick-carousel';
import Vue from 'vue';
import { default as testimoniesJson } from '../../../assets/testimonies.json'; //List of Testimonies

export default Vue.extend({
    name: 'Testimony',
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            carouselSettings: {
                responsive: [
                    {
                        breakpoint: 1924,
                        settings: {
                            arrows: false,
                            dots: true,
                            focusOnSelect: true,
                            infinite: true,
                            autoplay: true,
                            speed: 1000,
                            autoplaySpeed: 30000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            touchThreshold: 5,
                        },
                    },
                ],
            },
            testimonies: [],
        };
    },
    methods: {
        getImgUrl(partner: any) {
            //If the image is hosted in another website use property 'fullImgSrc' instead 'imgSrc' 
            if (partner.fullImgSrc) {
                return partner.fullImgSrc;
            }
            let images = require.context(
                '../../../assets/imgs/testimonies',
                false,
                /\.png$/
            );
            return images('./' + partner.imgSrc);
        },
    },
    beforeMount() {
        this.testimonies = testimoniesJson;
    },
});
