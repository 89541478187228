





































export default {
    name: 'Expertise',
};
