export const EstablishmentType: any = {
    medical: {
        id: 1,
        value: 'EHPAD médicalisé',
        text: 'EHPAD médicalisé',
    },
    alzheimer: {
        id: 2,
        value: 'EHPAD Alzheimer',
        text: 'EHPAD Alzheimer',
    },
    residence: {
        id: 3,
        value: 'Résidence services',
        text: 'Résidence services',
    },
    unknown: {
        id: 4,
        value: 'Je ne sais pas',
        text: 'Je ne sais pas',
    },
};
