export const Deadline: any = {
    immediate: {
        id: 1,
        value: 1,
        text: 'Immédiat',
    },
    oneMonth: {
        id: 3,
        value: 3,
        text: "Moins d' 1 mois",
    },
    threeMonths: {
        id: 4,
        value: 4,
        text: "Moins de 3 mois",
    },
};
